var $class="se2--file-upload",$name="fileUpload",$path="app/partials/fileUpload/index.js",$this={$class,$name,$path,};import Dropzone from 'dropzone';

import shell from 'app/modules/shell';

import addUtmParamsToFormData from 'app/components/utilities/addUtmParamsToFormData';
import validateFormElements from 'app/components/utilities/validateFormElements';

import { css, keyNames } from 'configs';

const onEnterClick = (event) => {
  const { code } = event;
  if (code === keyNames.enter) event.target.click();
};

const initializeDropzone = (element) => {
  const form = element.closest('form');
  const error = element.closest('.form-group').querySelector('.upload-error');

  return new Dropzone('.custom-dropzone', {
    paramName: 'FileAttachment',
    maxFiles: 1,
    maxFileSize: 1,
    url: form.action, // append url from from action attribute
    method: 'POST',
    autoDiscover: false,
    autoProcessQueue: false, // Prevent sending file immediately
    maxFilesize: 5, // File size limit 5mb
    timeout: 180000,
    acceptedFiles: 'image/*,application/pdf,.webp', // Trusted files
    previewTemplate: form.querySelector('.dz-template').innerHTML,

    init() {
      this.on('success', () => {
        // googleAnalytics.contactFormSubmit(formInstance.form.getAttribute('action'));
        const redirectURL = form.dataset.redirectUrl;

        if (redirectURL) window.location.href = redirectURL;
      });
      this.on('addedfile', () => {
        error.innerText = '';
        error.classList.add('hide');
        element.closest('.form-group').classList.remove(css.classNames.invalid);
      });
      this.on('complete', (file) => {
        if (file.status === 'error') {
          return;
        }

        form.reset();
        if (window.grecaptcha) window.grecaptcha.reset();
        this.removeFile(file);
      });
      this.on('sending', (file, xhr, formData) => {
        addUtmParamsToFormData(form);
        form.forEach((item) => {
          // FIX: Dropzone radiobutton bug. Do not remove.
          if (item.type === 'radio' && !item.checked) {
            return;
          }

          formData.append(item.name, item.value); // Append all fileds information to fromData before send
        });
      });

      this.on('error', (file, message) => {
        if (file.status === 'error') { this.removeFile(file); }

        if (message.startsWith('<!DOCTYPE html>')) return;
        error.innerText = message;
        error.classList.remove('hide');
      });

      this.on('removedfile', () => {
        error.innerText = '';
        error.classList.add('hide');
        element.closest('.form-group').classList.remove(css.classNames.invalid);
      });
    },
  });
};

const onSubmit = (event, dropzone) => {
  event.preventDefault();
  event.stopPropagation();

  const form = event.target.closest('form');
  const dropzoneElement = form.querySelector('.custom-dropzone');
  const formIsValid = validateFormElements(form);

  if (dropzoneElement.classList.contains('required') && dropzone.files.length === 0) {
    dropzoneElement.closest('.form-group').classList.add(css.classNames.invalid);
    return false;
  }

  if (!formIsValid) return false;

  addUtmParamsToFormData(form);

  if (dropzone.getQueuedFiles().length > 0) {
    dropzone.processQueue();
  } else {
    const blob = new Blob();
    blob.upload = { chunked: dropzone.defaultOptions?.chunking };
    dropzone.uploadFile(blob);
  }

  return false;
};

export default shell.registerComponent($this, ({ addEventListener, mount }) => {
  mount((element) => {
    const myDropzone = initializeDropzone(element);
    const form = element.closest('form');
    const customDropzone = element.querySelector('.custom-dropzone');
    const submitButton = form.querySelector(`.${css.classNames.submitButton}`);
    addEventListener(customDropzone, 'keydown', onEnterClick);
    addEventListener(submitButton, 'click', (event) => onSubmit(event, myDropzone));
  });
});
